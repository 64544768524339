<template lang="pug">
    #app
        //- transition(name="app-loading")
        app-preloader(v-if="loading", @destroy="loading = false")
        app-header
        client-only
            app-share(v-if="!$platform.isMobile")
            app-navigation
        main.main
            //- ui-transition(transitionName="fade")
            router-view(:key="$route.params.id")
        app-disclaimer
</template>

<script>
import './assets/css/backpack-filtered.scss';

import AppDisclaimer from './components/Disclaimer.vue';
import AppHeader from './components/Header.vue';
import AppNavigation from './components/Navigation.vue';
import AppShare from './components/Share.vue';
import AppPreloader from './components/Preloader.vue';
import UiTransition from './components/ui/Transition.vue';

export default {
    name: 'app',
    components: {
        AppDisclaimer,
        AppHeader,
        AppNavigation,
        AppShare,
        AppPreloader,
        UiTransition,
    },
    data: () => ({
        loading: true,
    }),
};
</script>

<style lang="scss">
@import "assets/css/fonts.scss";
@import "assets/css/vars.scss";

html {
    font-size: 18px;
    line-height: 1.25;

    @media (--viewport-laptop-m) {
        font-size: 16px;
    }

    @media (--viewport-tablet) {
        font-size: 20px;
    }

    @media (--viewport-mobile) {
        font-size: 16px;
    }
}

/* main styles */
body {
    font-family: var(--font-main);
    font-weight: 400;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    background: url('./assets/images/noise.png') var(--color-main-bg);
    background-repeat: repeat;
    background-size: initial;
    background-position: 0 0;
    color: var(--color-main-text);
}

#app {
    position: relative;
}

.main {
    width: 100%;
    margin: 0 auto;
    padding-top: constant(safe-area-inset-top);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    position: relative;
    overflow-x: hidden;
}

.app-loading {
    &-leave {
        &-active {
            transition: opacity 0.6s ease-in-cubic;
        }

        &-to {
            opacity: 0;
        }
    }
}
</style>

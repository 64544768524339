<template lang="pug">
    .icon(aria-hidden="true", :style="{ '--anim-delay': delay }", @click="(ev) => $emit('click', ev)")
        img(:src="require(`../../assets/images/icons/${src}.svg`)", alt="")
</template>

<script>
import { articleId } from '../../assets/js/prop-types';

export default {
    name: 'article-icon',
    props: {
        src: {
            type: String,
            required: true,
        },
        currentArticleId: {
            ...articleId,
            required: false,
        },
    },
    data: () => ({ delay: '0s' }),
    created() {
        this.delay = `${Math.floor(Math.random() * 20) / 20}s`;
    },
};
</script>

<style lang="scss" scoped>
.icon {
    img {
        width: 100%;
        object-fit: contain;
        object-position: center;
    }
}

.pulse {
    img {
        animation: pulse 3s infinite;
        animation-delay: var(--anim-delay);

        @keyframes pulse {
            0% {
                transform: scale(0.9);
            }

            70% {
                transform: scale(1.1);
            }

            100% {
                transform: scale(0.9);
            }
        }
    }
}

.opacity-75 {
    opacity: 0.75;
}

.opacity-50 {
    opacity: 0.5;
}

.multiply {
    mix-blend-mode: multiply;
}
</style>

import Vue from 'vue';

class Platform {
    constructor() {
        this.store = new Vue({
            data: {
                isMobile: window.innerWidth <= 1024,
            },
        });

        window.addEventListener('resize', () => {
            const windowMobile = window.innerWidth <= 1024;

            if (windowMobile !== this.isMobile) this.store.isMobile = windowMobile;
        });
    }

    get isMobile() {
        return this.store.isMobile;
    }
}

const PlatformPlugin = (VueInstance) => {
    VueInstance.prototype.$platform = new Platform();
};

export default PlatformPlugin;

const articleStub = {
    meta: {
        title: '',
        description: '',
    },
    content: null,
};
const totalIds = [1, 2, 3, 4, 5, 6];
const availableIds = [1, 2, 3, 4, 5, 6];

const handleArticleLoad = async (id = 1, isAvailable = false) => {
    if (!isAvailable) {
        return {
            id,
            ...articleStub,
        };
    }

    try {
        // eslint-disable-next-line prefer-template
        const module = await import('../../assets/data/articles/' + id + '/content.json');
        const { meta, content } = module.default || module;

        return {
            id,
            content,
            meta,
        };
    } catch (error) {
        return {
            id,
            ...articleStub,
        };
    }
};

const articlesModule = {
    namespaced: true,
    state: {
        availableIds,
        totalIds,
        articles: [],
        loaded: [],
    },
    getters: {
        getArticleById: ({ articles }) => (id) => {
            if (typeof id === 'number') {
                return articles.find(({ id: articleId }) => id === articleId) || articleStub;
            }

            return articleStub;
        },
        articlesMeta: ({ articles }) => articles.map(({ id, meta }) => ({ id, meta })),
        articlesContent: ({ articles }) => articles.map(({ id, content }) => ({ id, content })),
    },
    mutations: {
        setArticles(state, articlesData) {
            const ids = new Set();
            const filetered = [...state.articles, ...articlesData].filter(({ id }) => (!ids.has(id) ? ids.add(id) : false));

            state.articles = filetered.sort(({ id: aId }, { id: bId }) => aId - bId);
        },
        setLoaded(state, loaded) {
            state.loaded = [...new Set([...state.loaded, ...loaded])];
        },
    },
    actions: {
        async loadArticles({ commit }, ids = totalIds) {
            const articlesData = await Promise.all(ids.map((id) => handleArticleLoad(id, availableIds.includes(id))));

            commit('setArticles', articlesData);
            commit('setLoaded', ids);
        },
    },
};

export default articlesModule;

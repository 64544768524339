export const requiredString = (prop) => typeof prop === 'string';

export const filledArray = (prop) => Array.isArray(prop) && prop.length > 0;

export const requiredObject = (prop) => typeof prop === 'object' && prop !== null && Object.entries(prop).length > 0;

export const oneOf = (prop, entities) => filledArray(entities) && entities.includes(prop);

export const contentImageName = (prop) => prop === null
    || typeof prop === 'string'
    || typeof prop === 'undefined'
    || typeof prop === 'number';

export const articleId = {
    validator: (prop) => typeof prop === 'number',
    required: true,
};

<template lang="pug">
    aside.share(:class="{ 'mobile': $platform.isMobile }")
        button.share-item(
            v-for="shareItem in shareArr",
            :key="shareItem.network",
            @click="$analytics.share(shareItem.label)"
        )
            share-network(
                :url="sharingProps.url",
                :title="sharingProps.title",
                :description="sharingProps.description",
                :network="shareItem.network"
            )
                img(:src="shareItem.icon", :alt="shareItem.label")
</template>

<script>
import vk from '../assets/images/icons/share/vk.svg';
import tw from '../assets/images/icons/share/tw.svg';

import {
    url, title, description,
} from '../assets/data/meta.json';

const sharingProps = {
    url,
    title,
    description,
    isVkParse: false,
    image: `${url}share.jpg`,
    media: `${url}share.jpg`,
};

export default {
    name: 'app-share',
    computed: {
        sharingProps: () => sharingProps,
        shareArr: () => [
            {
                label: 'VK',
                icon: vk,
                network: 'vk',
            },
            {
                label: 'Twitter',
                icon: tw,
                network: 'twitter',
            },
        ],
    },
};
</script>

<style lang="scss" scoped>
.share {
    position: absolute;
    top: 140px;
    right: var(--offset-x);
    z-index: 999;

    &-item {
        width: 36px;
        height: 32px;
        margin-bottom: 16px;
        display: block;
        transition: transform 0.2s ease-in-quad;

        &:hover {
            transform: scale(1.1);
            transition: transform 0.2s ease-out-quad;
        }
    }

    &.mobile {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        position: static;
        top: unset;
        right: unset;

        .share-item {
            margin: 0 rem(6);
        }
    }
}
</style>

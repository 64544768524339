const intersectionModule = {
    namespaced: true,
    state: {
        entries: {},
        articleSectionIntersected: null,
    },
    getters: {
        getEntry: ({ entries }) => (key) => (key in entries ? entries[key] : null),
    },
    mutations: {
        patchEntries(state, intersectionEntriesPatch) {
            state.entries = {
                ...state.entries,
                ...intersectionEntriesPatch,
            };
        },
        setSectionIntersection(state, sectionIndex) {
            state.articleSectionIntersected = sectionIndex;
        },
    },
    actions: {
        patchEntries({ commit }, intersectionEntriesPatch) {
            commit('patchEntries', intersectionEntriesPatch);
        },
        setSectionIntersection({ commit }, sectionIndex) {
            commit('setSectionIntersection', sectionIndex);
        },
    },
};

export default intersectionModule;

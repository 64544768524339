<template lang="pug">
    .tooltip-wrap(
        @mouseenter="show = true",
        @mouseleave="show = false",
    )
        slot
        aside.tooltip(
            v-if="typeof text === 'string' && !$platform.isMobile",
            aria-hidden="true",
            :class="{ 'show': show }",
            :style="Array.isArray(position) ? { left: position[0], top: position[1], right: 'unset', bottom: 'unset' } : {}"
        )
            p.text(v-html="$sanitize(text)")
</template>

<script>
export default {
    name: 'ui-tooltip',
    props: {
        text: {
            validator: (prop) => typeof prop === 'string' || prop === null,
            required: true,
        },
        position: {
            validator: (prop) => prop === null || (Array.isArray(prop) && prop.length === 2),
            default: null,
        },
    },
    data: () => ({
        show: false,
    }),
};
</script>

<style lang="scss" scoped>
.tooltip-wrap {
    position: relative;

    .tooltip {
        min-width: rem(180);
        max-width: rem(240);
        padding: rem(6);
        position: absolute;
        top: calc(100% + #{rem(6)});
        right: calc(100% + #{rem(12)});
        z-index: 9999;
        font-size: rem(12);
        background-color: var(--light-gray);
        color: var(--color-main-text);
        opacity: 0;
        transform: translateY(16px);
        visibility: hidden;
        transition: transform 0.5s ease-in-quad, opacity 0.3s ease-in-quad, visibility 0s ease 0.3s;
        pointer-events: none;

        &.show {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
            transition: transform 0.35s ease-out-quad, opacity 0.2s ease-out-quad;
        }

        p {
            margin: 0;
            padding: 0;
        }
    }
}
</style>

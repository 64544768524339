<template lang="pug">
    transition(:name="transitionName", mode="out-in")
        slot
</template>

<script>
export default {
    name: 'ui-transition',
    props: {
        transitionName: {
            validator: (prop) => {
                if (typeof prop === 'string') {
                    return ['switch', 'switch-px', 'fade'].includes(prop);
                }

                return false;
            },
            required: true,
        },
        transitionElementKey: {
            type: String,
            default: undefined,
        },
    },
};
</script>

<style lang="scss" scoped>
.switch-px,
.switch {
    &-enter {
        --translate: 50%;

        opacity: 0;
        transform: translateY(var(--translate));

        &-active {
            transition: transform 0.4s ease-out-quad, opacity 0.15s ease-out-quad;
        }

        &-to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &-leave {
        &-active {
            transition: transform 0.4s ease-in-quad, opacity 0.35s ease-in-quad;
        }

        &-to {
            --translate: -25%;

            opacity: 0;
            transform: translateY(var(--translate));
        }
    }
}

.switch-px {
    &-enter {
        --translate: 48px;
    }

    &-leave {
        &-to {
            --translate: -24px;
        }
    }
}

.fade {
    &-enter {
        opacity: 0;

        &-active {
            transition: opacity 0.35s ease-out-quad;
        }

        &-to {
            opacity: 1;
        }
    }

    &-leave {
        &-active {
            transition: opacity 0.3s ease-in-quad;
        }

        &-to {
            opacity: 0;
        }
    }
}
</style>

export const getArticleId = (routeParams = {}) => {
    const { id } = routeParams;

    if (typeof id === 'string' && !Number.isNaN(parseInt(id, 10))) {
        return parseInt(id, 10);
    }

    if (typeof id === 'number') {
        return id;
    }

    return null;
};

export const sanitize = (str = '') => (typeof str === 'string' ? str.replace(/<[^>]*>?/gm, '') : '');

export const getInertedMouse = ({ clientX = 0, clientY = 0 }, mouse = { x: 0, y: 0 }) => {
    const { innerWidth, innerHeight } = window;
    const destX = (clientX - innerWidth / 2) / (innerWidth / 2);
    const destY = (clientY - innerHeight / 2) / (innerHeight / 2);
    const inertia = 0.045;

    return {
        x: (destX - mouse.x) * inertia,
        y: (destY - mouse.y) * inertia,
    };
};

<template lang="pug">
    nav.app-nav
        component.app-nav--item(
            v-for="item in navData",
            :key="item.key",
            :is="item.component",
            :to="item.to",
            :aria-label="item.ariaLabel",
            :aria-hidden="!item.isAvailable",
            :class="item.class",
            :style="item.style",
        )
            ui-tooltip.app-nav--illn(
                :text="item.meta", :class="{ 'disabled': !item.isAvailable }",
                :position="['20%', '10rem']"
            )
                component(
                    :is="item.illustration",
                    :class="'app-nav--illn'",
                    aria-hidden="true",
                    @click="() => handleClick(item.isAvailable, item.meta)"
                )
            .article-id(:class="{ active: currentArticleId === item.id }", aria-hidden="true")
                .inner
                    img(src="../../assets/images/icons/hex_teal.svg", alt="Зеленый шестиугольник")
                    img.active(src="../../assets/images/icons/hex.svg", alt="Лиловый шестиугольник")
                    span(v-html="item.id")
</template>

<script>
import UiTooltip from '../ui/Tooltip.vue';

const illustrations = {
    1: () => import(/* webpackChunkName: 'illn-1' */ '../../assets/images/navigation/1.svg?inline'),
    2: () => import(/* webpackChunkName: 'illn-2' */ '../../assets/images/navigation/2.svg?inline'),
    3: () => import(/* webpackChunkName: 'illn-3' */ '../../assets/images/navigation/3.svg?inline'),
    4: () => import(/* webpackChunkName: 'illn-4' */ '../../assets/images/navigation/4.svg?inline'),
    5: () => import(/* webpackChunkName: 'illn-5' */ '../../assets/images/navigation/5.svg?inline'),
    6: () => import(/* webpackChunkName: 'illn-6' */ '../../assets/images/navigation/6.svg?inline'),
    default: 'span',
};

export default {
    name: 'navigation-desktop',
    components: {
        UiTooltip,
    },
    props: {
        currentArticleId: {
            validator: (prop) => typeof prop === 'number' || prop === null,
            required: true,
        },
        articlesMeta: {
            type: Array,
            required: true,
        },
        availableIds: {
            type: Array,
            required: true,
        },
    },
    computed: {
        navData() {
            return this.articlesMeta.map(({ id, meta }, index) => {
                const isAvailable = this.availableIds.includes(id);

                return {
                    id,
                    isAvailable,
                    key: meta.title + id,
                    meta: meta.title,
                    component: isAvailable ? 'router-link' : 'div',
                    to: isAvailable ? { name: 'article', params: { id } } : undefined,
                    ariaLabel: isAvailable ? `Перейти к статье "${this.$sanitize(meta.title)}"` : undefined,
                    class: [
                        `app-nav--item__${id}`,
                        this.currentArticleId === id && 'active',
                        // this.blinkId === id && 'blink',
                    ],
                    style: {
                        zIndex: 10 + index,
                    },
                    illustration: Object.keys(illustrations).includes(`${id}`) ? illustrations[id] : illustrations.default,
                };
            });
        },
    },
    // data: () => ({
    //     blinkId: 0,
    //     interval: null,
    //     intervalDuration: 6000,
    // }),
    methods: {
        handleClick(isAvailable, name) {
            if (isAvailable) this.$emit('navItemClick', this.$sanitize(name));
        },
    },
    // mounted() {
    //     this.interval = setInterval(() => {
    //         this.blinkId = this.blinkId === this.articlesMeta.length ? 0 : this.blinkId + 1;
    //     }, this.intervalDuration);
    // },
    // beforeDestroy() {
    //     clearInterval(this.interval);
    //     this.interval = null;
    // },
};
</script>

<style lang="scss" scoped>
.app-nav {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    color: #a8ded8;

    &--item {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        color: currentColor;
        cursor: not-allowed;
        text-decoration: none;

        &__1 {
            width: vw(236);
            top: vw(78);
            left: vw(24);

            .article-id {
                top: vw(130);
                left: vw(96);
            }
        }

        &__2 {
            width: vw(220);
            top: vw(195);
            left: vw(189);

            .article-id {
                top: vw(98);
                left: vw(14);
            }
        }

        &__3 {
            width: vw(175);
            top: vw(49);
            left: vw(338.5);

            .article-id {
                top: vw(158);
                left: vw(92);
            }
        }

        &__4 {
            width: vw(189);
            top: vw(10);
            left: vw(498);

            .article-id {
                top: vw(99);
                left: vw(83);
            }
        }

        &__5 {
            width: vw(171);
            top: vw(43);
            left: vw(650);

            .article-id {
                top: vw(94);
                left: vw(116);
            }
        }

        &__6 {
            width: vw(175);
            top: vw(202);
            left: vw(820);

            .article-id {
                top: vw(118);
                left: vw(74);
            }
        }

        .article-id {
            width: vw(48);
            position: absolute;

            .inner {
                width: vw(48);
                height: vw(41);
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                border: none;

                img {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 0;
                    object-fit: contain;

                    &.active {
                        z-index: 10;
                        opacity: 0;
                        transition: opacity 0.25s ease-in-quad;
                    }
                }

                span {
                    padding-bottom: vw(4);
                    display: inline-block;
                    position: relative;
                    z-index: 11;
                    font-size: vw(24);
                    font-weight: 700;
                    color: white;
                    text-decoration: none !important;
                }
            }

            &.active {
                img.active {
                    opacity: 1;
                    transition: opacity 0.35s ease-out-quad;
                }
            }
        }
    }

    &--illn {
        width: 100%;
        position: relative;
        object-fit: cover;
        overflow: visible;

        &.disabled {
            pointer-events: none;
        }
    }
}

a.app-nav--item {
    cursor: pointer;
    color: currentColor;
    transition: color 0.3s ease-in-quad;

    .accent-block,
    .svg-hexagon {
        fill: transparent;
        transition: fill 0.25s ease-in-quad;
    }

    .svg-hexagon {
        transition: stroke-width 0.3s ease-out-quad;
    }

    .accent-block {
        transition-duration: 0.2s;
    }

    &:hover,
    // &.blink,
    &.active {
        color: var(--teal);
        transition: color 0.35s ease-out-quad;

        .accent-block,
        .svg-hexagon {
            fill: rgba(212, 238, 235, 0.4);
            transition: fill 0.3s ease-out-quad;
        }

        .accent-block {
            fill: var(--violet);
            transition-duration: 0.3s;
        }
    }

    &.active {
        pointer-events: none;

        .svg-hexagon {
            stroke-width: 5;
            transition: stroke-width 0.3s ease-out-quad;
        }
    }
}
</style>

<template lang="pug">
    intersect(
        @change="([entry]) => handleIntersection(entry)",
        :threshold="[0.5, 1]"
    )
        section.navigation(:style="style.wrap")
            .nav-content
                ui-transition(transitionName="switch")
                    ui-title.title(v-html="selectedMeta.title", component="h1", :key="selectedMeta.title")
                navigation-mobile(
                    v-if="$platform.isMobile",
                    :currentArticleId="currentArticleId",
                    :articlesMeta="articlesMeta",
                    :availableIds="availableIds",
                    @navItemClick="(name) => $analytics.navigateToArticle(name)"
                )
                .article-meta
                    .scroll-down(
                        v-if="$platform.isMobile",
                        aria-hidden="true",
                        @click="scrollToArticle"
                    )
                        scroll-down
                    ui-transition(transitionName="switch-px")
                        ui-paragraph(v-html="selectedMeta.description", ref="text", :key="selectedMeta.description")
            navigation-desktop(
                v-if="!$platform.isMobile",
                :currentArticleId="currentArticleId",
                :articlesMeta="articlesMeta",
                :availableIds="availableIds",
                @navItemClick="(name) => $analytics.navigateToArticle(name)"
            )
            .scroll-down(
                v-if="!$platform.isMobile",
                aria-hidden="true",
                @click="scrollToArticle",
                :style="style.arrow"
            )
                scroll-down
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import Intersect from 'vue-intersect';

import { getArticleId } from '../assets/js/utils';

import UiTitle from './ui/Title.vue';
import UiParagraph from './ui/Paragraph.vue';
import UiTransition from './ui/Transition.vue';
import NavigationDesktop from './navigation/Desktop.vue';
import NavigationMobile from './navigation/Mobile.vue';

const ScrollDown = () => import(/* webpackChunkName: 'scroll-down' */ '../assets/images/icons/scroll-down.svg?inline');

export default {
    name: 'app-navigation',
    components: {
        Intersect,
        NavigationDesktop,
        NavigationMobile,
        UiParagraph,
        UiTitle,
        ScrollDown,
        UiTransition,
    },
    computed: {
        ...mapGetters('articles', ['articlesMeta']),
        ...mapState('articles', ['availableIds']),
        currentArticleId() {
            return getArticleId(this.$route.params);
        },
        selectedMeta() {
            const metaObj = this.articlesMeta.find(({ id }) => id === this.currentArticleId) || { meta: {} };

            return metaObj.meta;
        },
        style() {
            if (this.$platform.isMobile) return {};

            return {
                wrap: {
                    height: `${this.height}px`,
                },
                arrow: {
                    bottom: this.height > window.innerHeight ? '15vh' : null,
                },
            };
        },
    },
    data: () => ({
        height: 980,
    }),
    methods: {
        ...mapActions('intersection', ['patchEntries']),
        scrollToArticle() {
            const articleNode = document.querySelector('#article-wrap');

            if (articleNode instanceof Element) {
                articleNode.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth',
                });
            }
        },
        setContentHeight() {
            const { innerWidth } = window;
            const predefinedHeight = (600 / 10.85) * (innerWidth / 100);
            const { top: textTop, height: textHeight } = this.$refs.text?.$el?.getBoundingClientRect();
            const { top: wrapTop } = this.$el?.getBoundingClientRect();
            const withText = textHeight + (textTop - wrapTop);

            this.height = Math.max(predefinedHeight, withText);
        },
        handleIntersection(entry) {
            this.patchEntries({ mainNav: entry });
        },
    },
    mounted() {
        if (!this.$platform.isMobile) {
            window.addEventListener('resize', this.setContentHeight);
            this.$nextTick(() => {
                this.setContentHeight();
            });
        }
    },
    beforeDestroy() {
        if (!this.$platform.isMobile) {
            window.removeEventListener('resize', this.setContentHeight);
        }
    },
};
</script>

<style lang="scss" scoped>
section.navigation {
    width: 100%;
    height: vw(580);
    padding: rem(116) var(--offset-x) 0;
    position: relative;
    box-sizing: border-box;

    @media (--viewport-tablet) {
        height: unset;
        padding-top: rem-mobile(110);
    }

    .nav-content {
        width: 100%;
        position: relative;

        &::before {
            content: url('../assets/images/icons/decoration/section-start-left.svg');
            position: absolute;
            top: 0;
            left: calc(-1 * var(--offset-x));
            z-index: -1;
            transform: translateY(-33%);
            pointer-events: none;
        }

        @media (--viewport-tablet) {
            &::before {
                content: none;
                display: none;
            }
        }

        .article-meta {
            width: vw(268);
            position: absolute;
            top: vw(272);
            left: calc(50% + #{vw(38)});
            z-index: 1000;

            @media (--viewport-tablet) {
                width: 100%;
                margin: rem-mobile(54) 0 0 0;
                position: relative;
                top: unset;
                left: unset;
            }
        }
    }

    .scroll-down {
        width: rem(34);
        position: absolute;
        bottom: rem(48);
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;

        @media (--viewport-tablet) {
            width: rem-mobile(28);
            bottom: calc(100% + #{rem-mobile(54)});
            left: 0;
            transform: translateX(0);
        }

        svg {
            overflow: visible;
        }

        .scroll-down_svg__scroll-down-line {
            transform-origin: 50% 100%;
            animation: scroll-down 3s ease-in-out 0s infinite alternate-reverse;

            @keyframes scroll-down {
                0% {
                    transform: scaleY(1);
                }

                100% {
                    transform: scaleY(1.35);
                }
            }

            @media (--viewport-tablet) {
                animation-duration: 1.5s;

                @keyframes scroll-down {
                    0% {
                        transform: scaleY(0.25);
                    }

                    100% {
                        transform: scaleY(0.35);
                    }
                }
            }
        }
    }
}
</style>

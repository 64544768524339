<template lang="pug">
    component.ui-title(
        :is="component",
        :class="classNames"
    )
        slot
</template>

<script>
import { oneOf } from '../../assets/js/prop-types';

export default {
    name: 'ui-title',
    props: {
        variant: {
            validator: (prop) => oneOf(prop, ['title', 'subtitle', 'subtitle-2', 'subtitle-3', 'subtitle-4']),
            default: 'title',
        },
        component: {
            type: String,
            default: 'h2',
        },
    },
    computed: {
        classNames() {
            return [
                this.variant,
            ];
        },
    },
};
</script>

<style lang="scss" scoped>
.ui-title {
    font-family: var(--font-accent);
    font-weight: 700;
    color: var(--teal);

    ::v-deep em {
        color: var(--violet);
    }

    &.title {
        padding-bottom: rem(4);
        font-size: rem(56);
        line-height: 1;

        @media (--viewport-tablet) {
            font-size: rem-mobile(48);
            line-height: 1.125;
        }
    }

    &.subtitle {
        font-size: rem(40);
        line-height: 1.125;

        @media (--viewport-tablet) {
            font-size: rem-mobile(34);
            line-height: 1.175;
        }
    }

    &.subtitle-2 {
        font-family: var(--font-accent);
        font-size: rem(24);
        line-height: 1.05;

        @media (--viewport-tablet) {
            font-size: rem-mobile(24);
            line-height: 1.25;
        }
    }

    &.subtitle-3 {
        font-family: var(--font-accent);
        font-size: rem(34);
        line-height: 1.15;

        @media (--viewport-tablet) {
            font-size: rem-mobile(34);
        }
    }

    &.subtitle-4 {
        font-family: var(--font-main);
        font-size: rem(20);
        line-height: 1.25;

        @media (--viewport-tablet) {
            font-size: rem-mobile(20);
        }
    }
}
</style>

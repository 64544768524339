import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

import articles from './modules/articles';
import intersection from './modules/intersection';

Vue.use(Vuex);

const loggerPlugin = createLogger({
    collapsed: true,
});

const store = new Vuex.Store({
    modules: {
        articles,
        intersection,
    },
    plugins: process.env.NODE_ENV === 'production' ? [] : [loggerPlugin],
});

export default store;

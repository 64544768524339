<template lang="pug">
    header.app-header(:class="{ 'show': showState }")
        a.logo(
            href="https://lenta.ru/",
            target="_blank",
            rel="noopener noreferrer",
            aria-label="Lenta.ru",
            @click="$analytics.linkClick('https://lenta.ru/')"
        )
            img(src="../assets/images/lenta-logo.svg", alt="Логотип lenta.ru")
        client-only
            .content-wrap(v-if="$platform.isMobile")
                ui-transition(transitionName="switch")
                    app-share.switch-item(v-if="active === 'share'")
                    app-section-nav.switch-item(
                        v-else-if="active === 'nav' && currentArticle !== null",
                        :articleId="currentArticle.id"
                        :content="currentArticle.content"
                    )
</template>

<script>
import { mapGetters } from 'vuex';

import { getArticleId } from '../assets/js/utils';

import AppShare from './Share.vue';
import AppSectionNav from './article/SectionNav.vue';
import UiTransition from './ui/Transition.vue';

export default {
    name: 'app-header',
    components: {
        AppShare,
        AppSectionNav,
        UiTransition,
    },
    computed: {
        ...mapGetters('articles', ['getArticleById']),
        ...mapGetters('intersection', ['getEntry']),
        currentArticle() {
            const article = this.getArticleById(getArticleId(this.$route.params));

            if (article.content === null || typeof article.id !== 'number') return null;

            return article;
        },
        active() {
            if (!this.$platform.isMobile) return null;

            const mainNav = this.getEntry('mainNav');
            const productBlock = this.getEntry('productBlock');

            if (mainNav instanceof IntersectionObserverEntry && productBlock instanceof IntersectionObserverEntry) {
                return mainNav.isIntersecting === false && productBlock.isIntersecting === false ? 'nav' : 'share';
            }

            return null;
        },
    },
    data: () => ({
        prevScrollPos: 0,
        showState: true,
    }),
    methods: {
        handleScroll() {
            if (window.pageYOffset > 64) {
                this.showState = this.prevScrollPos >= window.pageYOffset;
                this.prevScrollPos = window.pageYOffset;
            }
        },
    },
    mounted() {
        if (!this.$platform.isMobile) {
            setTimeout(() => {
                this.handleScroll();
                window.addEventListener('scroll', this.handleScroll);
            }, 1000);
        }
    },
    beforeDestroy() {
        if (!this.$platform.isMobile) {
            window.removeEventListener('scroll', this.handleScroll);
        }
    },
};
</script>

<style lang="scss" scoped>
header.app-header {
    width: 100%;
    padding: rem(12) var(--offset-x);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: var(--light-green);
    box-shadow: 0 0 rem(13) rgba(102, 102, 102, 0.5);
    transform: translateY(-100%);
    visibility: hidden;
    transition: transform 0.3s ease-in-quad, visibility 0s ease 0.3s;
    will-change: transform;

    @media (--viewport-tablet) {
        padding-top: rem-mobile(12);
        padding-bottom: rem-mobile(12);
    }

    &.show {
        transform: translateY(0);
        visibility: visible;
        transition: transform 0.35s ease-out-quad, visibility 0s ease 0s;
    }

    .logo {
        width: rem(114);
        max-height: rem(32);
        display: flex;
        justify-content: center;
        align-items: center;

        @media (--viewport-tablet) {
            min-width: rem(52);
            max-width: 25%;
            max-height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .content-wrap {
        @media (--viewport-tablet) {
            width: 60%;
            height: rem-mobile(48);
            padding: rem-mobile(8);
            overflow-y: visible;
            overflow-x: auto;
        }
    }
}
</style>

import { base } from './assets/data/meta.json';

export const registerServiceWorker = () => {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            navigator.serviceWorker.register(`${base}sw.js`).then((registration) => {
                console.log('SW registered: ', registration);
            }).catch((registrationError) => {
                console.log('SW registration failed: ', registrationError);
            });
        });
    }

    // if ('serviceWorker' in navigator) {
    //     navigator.serviceWorker.getRegistrations().then((registrations) => {
    //         registrations.forEach((registration) => {
    //             registration.unregister();
    //         });
    //     }).catch((err) => {
    //         console.log('Service Worker registration failed: ', err);
    //     });
    // }
};

<template lang="pug">
    .wrap
        ui-title.text(v-html="disclaimer", variant="subtitle")
</template>

<script>
import UiTitle from './ui/Title.vue';

export default {
    name: 'app-disclaimer',
    components: {
        UiTitle,
    },
    computed: {
        disclaimer: () => 'Имеются противопоказания, необходима консультация&nbsp;специалиста',
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    width: 100vw;
    padding: rem(20) rem(28);
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100000;
    background-color: var(--light-green);

    .text {
        font-size: rem(40);
        text-transform: uppercase;
        color: var(--violet);
        text-align: center;

        @media (--viewport-tablet) {
            font-size: rem-mobile(20);
        }
    }
}
</style>

<template lang="pug">
    aside.section-nav(
        :class="{ 'show': showState }",
        v-if="Array.isArray(navItems)",
        :style="style.wrap"
    )
        ui-tooltip.nav-item(
            v-for="{ tooltip, index } in navItems",
            :text="tooltip",
            :key="index",
            :class="{ 'active': articleSectionIntersected === index - 1 }"
        )
            button.button(@click="handleClick(index)")
                article-icon.icon(v-if="$platform.isMobile", :src="articleSectionIntersected === index - 1 ? 'hex' : 'hex_teal'")
                article-icon.icon(v-else, src="hex" class="pulse", :style="{ '--anim-delay': `${index * 0.25}s` }")
                span(v-html="`${articleId}.${index}`")
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import { filledArray } from '../../assets/js/prop-types';

import ArticleIcon from './Icon.vue';
import UiTooltip from '../ui/Tooltip.vue';

export default {
    name: 'app-section-nav',
    components: {
        ArticleIcon,
        UiTooltip,
    },
    props: {
        articleId: {
            type: Number,
            required: true,
        },
        content: {
            validator: filledArray,
            required: true,
        },
    },
    computed: {
        ...mapGetters('intersection', ['getEntry']),
        ...mapState('intersection', ['articleSectionIntersected']),
        showState() {
            if (this.$platform.isMobile) return true;

            const mainNav = this.getEntry('mainNav');
            const productBlock = this.getEntry('productBlock');

            if (mainNav instanceof IntersectionObserverEntry && productBlock instanceof IntersectionObserverEntry) {
                return mainNav.isIntersecting === false && productBlock.isIntersecting === false;
            }

            return false;
        },
        navItems() {
            if (Array.isArray(this.content)) {
                return this.content.map((sectionData, index) => {
                    const title = Array.isArray(sectionData) ? sectionData.find(({ type }) => type === 'title') : {};
                    const tooltip = typeof title === 'object' && 'text' in title ? title.text : null;

                    return {
                        index: index + 1,
                        tooltip,
                    };
                });
            }

            return null;
        },
        style() {
            if (!this.$platform.isMobile) return {};
            if (!(Array.isArray(this.navItems) && this.navItems.length > 0)) return {};

            const width = this.navItems.length * 32;

            return {
                wrap: {
                    width: `${width}px`,
                },
            };
        },
    },
    methods: {
        handleClick(index) {
            const article = document.querySelector(`#app-article-section__${index}`);

            if (article instanceof Element) {
                article.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth',
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.section-nav {
    width: rem(112);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    position: fixed;
    top: 33%;
    right: rem(8);
    z-index: 999;
    pointer-events: none;

    .nav-item {
        width: rem(48);
        height: rem(42);
        margin-bottom: rem(74);
        padding-bottom: rem(4);
        transform: translateX(20%);
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.25s ease-in-quad, transform 0.3s ease-in-quad;

        .button {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 1;
            font-family: var(--font-accent);
            font-size: rem(22);
            font-weight: 700;
            color: white;
        }

        .icon {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            object-fit: contain;
            transform-origin: 50% 50%;
            transform: scale(1);
            transition: transform 0.25s ease-in-quad;
        }

        &:nth-child(2n) {
            transform: translate(20%, 50%);
        }

        &:hover {
            .icon {
                transform: scale(1.3);
                transition: transform 0.3s ease-out-quad;
            }
        }
    }

    @for $i from 1 to 10 {
        .nav-item:nth-child(#{$i}) {
            transition-delay: $i * 0.025s;
        }
    }

    &.show {
        pointer-events: all;

        .nav-item {
            transform: translateX(0);
            opacity: 1;
            pointer-events: all;
            transition: opacity 0.3s ease-out-quad, transform 0.45s ease-out-quad;

            &:nth-child(2n) {
                transform: translate(0, 50%);
            }
        }

        // check coverage later
        @for $i from 1 to 4 {
            .nav-item:nth-child(#{$i}) {
                transition-delay: $i * 0.035s;
            }
        }
    }

    @media (--viewport-tablet) {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        position: static;
        top: unset;
        right: unset;

        .nav-item {
            width: 28px;
            height: 20px;
            margin: 0 4px;
            margin-bottom: 0;
            padding-bottom: 0;
            transform: none;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.25s ease-in-quad, transform 0.3s ease-in-quad;

            .button {
                font-size: 12px;
            }

            .icon {
                transform: scale(1);
            }

            &:nth-child(2n) {
                transform: translate(0, 50%);
            }

            &:hover {
                .icon {
                    transform: scale(1);
                }
            }

            &.active {
                pointer-events: none;

                .icon {
                    transform: scale(1.2);
                }
            }
        }
    }
}
</style>

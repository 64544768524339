/* eslint-disable max-classes-per-file */
import VueGtm from 'vue-gtm';

import { origin as rawMetaOrigin, GTM } from '../assets/data/meta.json';

const isProd = process.env.NODE_ENV === 'production';

const checkProdGTM = () => {
    if (!isProd) return false;

    const { origin } = window.location;
    const { origin: metaOrigin } = new URL(rawMetaOrigin);

    return metaOrigin === origin && GTM !== 'GTM-XXXXXX';
};

const defaultTrackParams = {
    event: 'eventTracking',
    action: 'Click',
};

class DevGTMTracker {
    trackEvent(data) {
        console.debug('Tracking event with params: ', data);
    }
}

class AnalyticsProxy {
    constructor(vueGtm) {
        this.tracker = vueGtm;
    }

    sendEvent(params) {
        this.tracker.trackEvent({
            ...defaultTrackParams,
            ...params,
        });
    }

    linkClick(href) {
        this.sendEvent({
            category: `Link click ${href}`,
            label: href,
        });
    }

    articleLinkClick(href) {
        this.sendEvent({
            category: `Article link click ${href}`,
            label: href,
        });
    }

    visitArticle(articleName) {
        this.sendEvent({
            category: `Visited "${articleName}"`,
            label: articleName,
            action: 'Page view',
        });
    }

    navigateToArticle(articleName) {
        this.sendEvent({
            category: `Navigate to "${articleName}" via top navigation`,
            label: articleName,
        });
    }

    ctaClick(articleName) {
        this.sendEvent({
            category: `Navigate to "${articleName}" via CTA button`,
            label: articleName,
        });
    }

    share(platform) {
        this.sendEvent({
            category: `Shared via ${platform}`,
            label: platform,
            action: 'Share',
        });
    }
}

const getAnalyticsProxyTracker = (Vue, router) => {
    if (checkProdGTM()) {
        Vue.use(VueGtm, {
            id: GTM,
            enabled: true,
            debug: false,
            vueRouter: router,
        });

        return Vue.prototype.$gtm;
    }

    return new DevGTMTracker();
};

const AnalyticsPlugin = (Vue, { router }) => {
    const tracker = getAnalyticsProxyTracker(Vue, router);
    const analyticsProxy = new AnalyticsProxy(tracker);

    Vue.prototype.$analytics = analyticsProxy;
};

export default AnalyticsPlugin;

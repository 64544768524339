import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/article/:id',
        name: 'article',
        component: () => import(/* webpackChunkName: 'app-article' */ '../views/Article.vue'),
    },
    {
        path: '*',
        redirect: '/article/1/',
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }

        return { x: 0, y: 0 };
    },
});

export default router;

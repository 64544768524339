<template lang="pug">
    aside.preloader(aria-hidden="true")
        .wrap
            .center
                hex-green.center-image
                transition-group.icon-wrap(name="preloader-switch", tag="div")
                    component.icon(
                        v-for="({ key, src }, index) in icons",
                        :key="key",
                        :is="src",
                        :id="key",
                        v-if="index === current"
                    )
            .side(
                v-for="(item, index) in icons",
                :key="index",
                :class="{ [`item__${index}`]: true, 'active': index === current }"
            )
                hex-violet.side-image
</template>

<script>
/* eslint-disable import/no-unresolved */
import Illn1 from '../assets/images/preloader/icons/1.svg?inline';
import Illn2 from '../assets/images/preloader/icons/2.svg?inline';
import Illn3 from '../assets/images/preloader/icons/3.svg?inline';
import Illn4 from '../assets/images/preloader/icons/4.svg?inline';
import Illn5 from '../assets/images/preloader/icons/5.svg?inline';
import Illn6 from '../assets/images/preloader/icons/6.svg?inline';
import HexGreen from '../assets/images/icons/hex_green.svg?inline';
import HexViolet from '../assets/images/icons/hex.svg?inline';
/* eslint-enable import/no-unresolved */

export default {
    name: 'app-preloader',
    components: {
        HexGreen,
        HexViolet,
    },
    computed: {
        icons() {
            return [
                { key: 'illn-1', src: Illn1 },
                { key: 'illn-2', src: Illn2 },
                { key: 'illn-3', src: Illn3 },
                { key: 'illn-4', src: Illn4 },
                { key: 'illn-5', src: Illn5 },
                { key: 'illn-6', src: Illn6 },
            ];
        },
    },
    data: () => ({
        current: 0,
        interval: null,
    }),
    methods: {
        async handleLoading() {
            window.addEventListener('load', () => {
                setTimeout(() => {
                    this.$emit('destroy');
                }, 200);
            });
        },
    },
    created() {
        this.interval = setInterval(() => {
            this.current = this.current + 1 > this.icons.length - 1 ? 0 : this.current + 1;
        }, 1000);
        this.handleLoading();
    },
    beforeDestroy() {
        clearInterval(this.interval);
        this.interval = null;
    },
};
</script>

// eslint-disable-next-line
import '!!style-loader!css-loader!postcss-loader!sass-loader!./assets/css/preloader.scss';

import Vue from 'vue';
import VueSocialSharing from 'vue-social-sharing';
import ClientOnly from 'vue-client-only';

import { registerServiceWorker } from './sw';

import App from './App.vue';
import router from './router';
import store from './store';

import PlatformPlugin from './plugins/platform';
import LocalesPlugin from './plugins/locales';
import AnalyticsPlugin from './plugins/analytics';

registerServiceWorker();

Vue.config.productionTip = false;

Vue.use(VueSocialSharing);
Vue.use(PlatformPlugin);
Vue.use(LocalesPlugin);

Vue.component('client-only', ClientOnly);

Vue.use(AnalyticsPlugin, {
    router,
});

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');

<template lang="pug">
    component.ui-paragraph(:is="component")
        slot
</template>

<script>
import { oneOf } from '../../assets/js/prop-types';

export default {
    name: 'ui-paragraph',
    props: {
        component: {
            validator: (prop) => oneOf(prop, ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div', 'span']),
            default: 'p',
        },
    },
};
</script>

<style lang="scss" scoped>
.ui-paragraph {
    font-size: rem(16);
    color: var(--color-main-text);

    @media (--viewport-tablet) {
        font-size: rem-mobile(16);
    }

    ::v-deep em {
        position: relative;
        background-color: var(--light-green);
    }

    ::v-deep a {
        color: var(--violet);
        transition: color 0.2s ease-in-quad;

        &:hover,
        &:focus,
        &:active {
            color: currentColor;
            transition: color 0.25s ease-out-quad;
        }
    }
}
</style>

<template lang="pug">
    nav.app-nav
        .links
            component.app-nav--item-wrap(
                v-for="item in navData",
                :key="item.key",
                :is="item.component",
                :to="item.to",
                :aria-label="item.ariaLabel",
                :aria-hidden="!item.isAvailable"
            )
                .app-nav--item(:class="item.class", @click="handleClick(item.isAvailable, item.meta)")
                    article-icon.icon(
                        :src="item.isAvailable ? 'hex_teal' : 'hex_green'",
                        :class="{ 'pulse': !item.class.includes('active') }"
                    )
                    article-icon.icon.active(src="hex")
                    span.count {{ item.id }}
        .illn(aria-hidden="true")
            component(
                :is="illustration",
                class="app-nav--illn"
            )
</template>

<script>
import ArticleIcon from '../article/Icon.vue';

const illustrations = {
    1: () => import(/* webpackChunkName: 'illn-1' */ '../../assets/images/navigation/mobile/1.svg?inline'),
    2: () => import(/* webpackChunkName: 'illn-2' */ '../../assets/images/navigation/mobile/2.svg?inline'),
    3: () => import(/* webpackChunkName: 'illn-3' */ '../../assets/images/navigation/mobile/3.svg?inline'),
    4: () => import(/* webpackChunkName: 'illn-4' */ '../../assets/images/navigation/mobile/4.svg?inline'),
    5: () => import(/* webpackChunkName: 'illn-5' */ '../../assets/images/navigation/mobile/5.svg?inline'),
    6: () => import(/* webpackChunkName: 'illn-6' */ '../../assets/images/navigation/mobile/6.svg?inline'),
    default: 'span',
};

export default {
    name: 'navigation-mobile',
    components: {
        ArticleIcon,
    },
    props: {
        currentArticleId: {
            validator: (prop) => typeof prop === 'number' || prop === null,
            required: true,
        },
        articlesMeta: {
            type: Array,
            required: true,
        },
        availableIds: {
            type: Array,
            required: true,
        },
    },
    computed: {
        navData() {
            return this.articlesMeta.map(({ id, meta }) => {
                const isAvailable = this.availableIds.includes(id);

                return {
                    id,
                    isAvailable,
                    key: meta.title + id,
                    component: isAvailable ? 'router-link' : 'div',
                    to: isAvailable ? { name: 'article', params: { id } } : undefined,
                    ariaLabel: isAvailable ? `Перейти к статье "${this.$sanitize(meta.title)}"` : undefined,
                    class: [`app-nav--item__${id}`, this.currentArticleId === id && 'active'],
                    meta: meta.title,
                };
            });
        },
        illustration() {
            const { currentArticleId: id } = this;

            return Object.keys(illustrations).includes(`${id}`) ? illustrations[id] : illustrations.default;
        },
    },
    methods: {
        handleClick(isAvailable, name) {
            if (isAvailable) this.$emit('navItemClick', this.$sanitize(name));
        },
    },
};
</script>

<style lang="scss" scoped>
$maw: 450;
$multiplier: $maw / 260;

.app-nav {
    width: 100%;
    margin: rem-mobile(48) 0 0 0;
    padding: 0;
    position: relative;

    .links {
        width: vw-mobile(260);
        max-width: #{$maw}px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        .app-nav--item-wrap {
            width: vw-mobile(48);
            max-width: #{48 * $multiplier}px;
            height: vw-mobile(41);
            max-height: #{41 * $multiplier}px;
            margin: 0 vw-mobile(4);
            text-decoration: none;

            &:nth-child(2n) {
                transform: translateY(-50%);
            }
        }

        .app-nav--item {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .icon {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;

                &::v-deep img {
                    width: 100%;
                    height: 100%;
                }

                &.active {
                    z-index: 2;
                    opacity: 0;
                    transition: opacity 0.2s ease-in-quad;
                }
            }

            .count {
                position: relative;
                z-index: 3;
                font-size: rem-mobile(18);
                font-weight: 700;
                line-height: 1;
                color: white;
            }

            &.active {
                .icon {
                    &.active {
                        opacity: 1;
                        transition: opacity 0.3s ease-out-quad;
                    }
                }
            }
        }
    }

    .illn {
        width: vw-mobile(180);
        max-width: #{180 * $multiplier}px;
        margin: rem-mobile(24) auto 0 auto;

        .app-nav--illn {
            width: 100%;
            overflow: visible;
        }
    }
}

// .app-nav {
//     width: 100%;
//     height: vw-mobile(220);
//     max-height: 424px;
//     margin: rem-mobile(48) 0 0 0;
//     padding: 0;
//     position: relative;

//     .links {
//         width: vw-mobile(260);
//         max-width: 500px;
//         margin: 0 auto;
//         display: flex;
//         justify-content: space-between;

//         .app-nav--item {
//             width: vw-mobile(48);
//             max-width: 94px;
//             height: vw-mobile(41);
//             max-height: 80px;
//             margin: 0 vw-mobile(4);
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             position: relative;
//             text-decoration: none;

//             .icon {
//                 width: 100%;
//                 height: 100%;
//                 position: absolute;
//                 top: 0;
//                 left: 0;
//                 z-index: 1;

//                 &::v-deep img {
//                     width: 100%;
//                     height: 100%;
//                 }

//                 &.active {
//                     z-index: 2;
//                     opacity: 0;
//                     transition: opacity 0.2s ease-in-quad;
//                 }
//             }

//             .count {
//                 position: relative;
//                 z-index: 3;
//                 font-size: rem-mobile(18);
//                 font-weight: 700;
//                 line-height: 1;
//                 color: white;
//             }

//             &.active {
//                 .icon {
//                     &.active {
//                         opacity: 1;
//                         transition: opacity 0.3s ease-out-quad;
//                     }
//                 }
//             }

//             &:nth-child(2n) {
//                 transform: translateY(-50%);
//             }
//         }
//     }

//     .illn {
//         width: 100%;
//         height: 90%;
//         position: absolute;
//         bottom: 0;
//         left: 0;

//         .app-nav--illn {
//             width: 67%;
//             max-width: 500px;
//             height: 100%;
//             margin: 0 auto;
//             display: block;
//             object-fit: cover;
//         }
//     }
// }
</style>
